import { useStaticQuery, graphql } from "gatsby"
//hero-image2.jpeg r
export const useHeroImage = () => {
  const { heroImage } = useStaticQuery(
    graphql`
      query HeroImage {
        heroImage: file(relativePath: { eq: "newset01/Cafe-front.png" }) {
          childImageSharp {
            fluid(quality: 60, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )
  return heroImage
}
