import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { useSiteMetadata } from "../components/hooks/use-site-metadata"
import { useHeroImage } from "../components/hooks/useHeroImage"

import Layout from "../layouts/layout.de"
import Image from "../components/image"
import SEO from "../components/seo"
import BackgroundImage from "gatsby-background-image"

import MainFeature from "../components/features/MainFeature.jsx"
import ImageGallery from "../components/features/ImageGallery.jsx"

const IndexPage = props => {
  const { title } = useSiteMetadata()
  const heroImage = useHeroImage()
  const backgroundFluidImageStack = [
    heroImage.childImageSharp.fluid,
    `linear-gradient(rgba(17, 17, 15, 0.43), rgba(47, 17, 17, 0.53))`,
  ].reverse()

  const pageData = {
    title: "Entdecke bei uns",
    subTitle: "die Gemütlichkeit",
    sectionTitle: "Wir lieben Kuchen...",
    body:
      "Herzlich willkommen im Café Milch & Zucker in Rendsburg am Nordostsee-Kanal. Wir haben seit dem 1. Mai 2021 wieder geöffnet und freuen uns dir unseren leckeren selbstgebackenen Kuchen oder herzhaftes Frühstück zu servieren. Genieße die Zeit bei uns und entdecke bei uns die Gemütlichkeit in unserem kleinen Café am Paradeplatz. Mit unseren Außenplätzen kannst du unseren Kuchen direkt unter der Sonne mit deinen Freunden & Liebsten genießen.",
    seoTitle:
      "Cafe Milch und Zucker in Rendsburg am Nordostsee-Kanal: Genieße bei uns selbstgebackenen Kuchen, Frühstück und Kaffee",
  }

  const formConfig = {
    id: "tina-tutorial-index",
    label: "Edit Page",
    fields: [
      {
        name: "title",
        label: "Title",
        component: "text",
      },
      {
        name: "subTitle",
        label: "Sub Title",
        component: "text",
      },
      {
        name: "sectionTitle",
        label: "Section Title",
        component: "text",
      },
      {
        name: "body",
        label: "Body",
        component: "textarea",
      },
      {
        name: "seoTitle",
        label: "SEO Title",
        component: "text",
      },
    ],
    initialValues: pageData,
    onSubmit: async () => {
      window.alert("Saved!")
    },
  }

  return (
    <Layout
      location={props.location}
      showHeader={true}
      imageData={backgroundFluidImageStack}
    >
      <SEO title={pageData.sectionTitle} />
      <MainFeature editableData={pageData} />
      <ImageGallery editableData={pageData} />
    </Layout>
  )
}

export default IndexPage
